import React, { Component } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { Heading, Map } from '../../components';

import css from './ListingPage.module.css';

class SectionCancellationMaybe extends Component {
  constructor(props) {
    super(props);
    this.state = { isStatic: true };
  }

  render() {
    const { className, rootClassName, geolocation, publicData, listingId, mapsConfig } = this.props;

    console.log(publicData, 'publicData')

    if (!publicData?.cancellationPolicy) {
      return null;
    }

    const classes = classNames(rootClassName || css.sectionMultiEnum, className);
    const { cancellationPolicy } = publicData;
    const {period, range, refundAfterFreeCancellation } = cancellationPolicy;


    return (
      <section className={classes}>
        <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
          <FormattedMessage id="ListingPage.cancellationTitle" />
        </Heading>

        <div className={css.info}>
          <p>
            <FormattedMessage id="ListingPage.cancellationPeriod" values={{period, range}} />
          </p>
          <p>
            <FormattedMessage id="ListingPage.cancellationRefund" values={{refundAfterFreeCancellation}} />
          </p>
        </div>
      </section>
    );
  }
}

SectionCancellationMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  listingId: null,
};

SectionCancellationMaybe.propTypes = {
  rootClassName: string,
  className: string,
  listingId: propTypes.uuid,
};

export default SectionCancellationMaybe;
